<template>
  <div class="app_con">
    <div class="p-20">
      <div class="flex justify-between m-b-20">
        <div>
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            border
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
        </div>
        <div class="flex m-b-20">
          <el-button
            type="success"
            @click="handleDoor('openDoor')"
            icon="el-icon-folder-opened"
            >开门</el-button
          >
          <el-button
            type="danger"
            @click="handleDoor('closeDoor')"
            icon="el-icon-folder"
            >关门</el-button
          >
          <el-button
            type="warning"
            @click="handleDoor('stayOpen')"
            icon="el-icon-check"
            >常开</el-button
          >
          <el-button
            type="info"
            @click="handleDoor('stayClose')"
            icon="el-icon-close"
            >常闭</el-button
          >
          <el-button
            type="primary"
            @click="handleDoor('normal')"
            icon="el-icon-folder-checked"
            >正常</el-button
          >
          <el-button @click="getList" icon="el-icon-refresh">刷新</el-button>
        </div>
      </div>

      <div class="content">
        <div class="channelsControlImageInfo">
          <div class="channelsControlImageInfo_body flex flex-wrap">
            <el-checkbox-group
              v-model="checkedChanne"
              @change="handleCheckedChanneChange"
            >
              <el-checkbox-button
                v-for="item in channelList"
                :key="item.id"
                :label="item.channelCode"
              >
                <div class="channeItem">
                  <div class="channeItemImg m-b-8">
                    <div class="doorImg" :class="doorStatus(item)"></div>
                  </div>
                  <div class="channeItemContent">
                    <div class="autoChangeLine font-12">
                      {{ item.channelName }}
                    </div>
                  </div>
                </div>
              </el-checkbox-button>
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      isIndeterminate: false,
      channelList: [],
      searchQuery: {
        pageNum: 1,
        pageSize: 1000,
      },
      checkAll: false,
      checkedChanne: [],
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    // 获取列表数据
    async getList() {
      try {
        const loading = this.$loading({
          lock: true,
          text: "加载中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let { searchQuery } = this;

        let result = await api.concierge.getList(searchQuery);

        if (result.code == 200) {
          loading.close();
          // 判断code是否等于200
          if (result.data.code == 403) {
            // 如果data.coded 等于403 说明大华平台登录过期需要重新登录
            let dhLogin = await api.common.dhLogin();
            if (dhLogin.code == 200) {
              // 大华平台登录成功 后重新获取设备列表
              this.getList();
            }
          } else {
            // 过滤第一条数据不要
            this.channelList = result.data.data.pageData
              .filter((x) => {
                return x.deviceCode != 1000023;
              })
              .map((x) => {
                return x.accessControlChannelList[0];
              });
            this.channelList.forEach((x, i) => {
              this.$set(this.channelList[i], "isSelected", false);
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 全选
    handleCheckAllChange(val) {
      this.checkedChanne = val
        ? this.channelList.map((x) => {
            return x.checkedChanne;
          })
        : [];
      this.isIndeterminate = false;
    },
    // 多选
    handleCheckedChanneChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.channelList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.channelList.length;
    },
    doorStatus(item) {
      let status = parseInt(item.status);
      let str = "doorOpen";
      switch (status) {
        // 开门
        case 1:
          str = "doorOpen";
          break;
        // 关门
        case 2:
          str = "doorClose";
          break;
        // 常开
        case 3:
          str = "doorAlwaysOpen";
          break;
        // 常关
        case 4:
          str = "doorAlwaysClose";
          break;
        // 正常
        case 5:
          str = "doorOpen";
          break;
      }
      return str;
    },
    // 门口的操作 type:操作的类型
    async handleDoor(type) {
      console.log("checkedChanne", this.checkedChanne);

      if (this.checkedChanne.length <= 0) {
        this.$message({
          type: "error",
          message: "请选择需要操作的门！",
        });
        return;
      }

      console.log("door-type", this.checkedChanne);
      let apiName = `/dh/door/${type}`;
      let resule = await api.concierge.changeDoor({
        apiName,
        params: this.checkedChanne,
      });
      try {
        if (resule.code == 200) {
          this.$message({
            type: "error",
            message: resule.data.errMsg == 'success' ?'操作成功！':resule.data.errMsg,
          });
          this.getList()
        }
      } catch (error) {
        console.log("error", error);
      }
      // switch (type) {
      //   // 开门
      //   case "openDoor":
      //     apiName = /dh/door/normal
      //     break;
      //   // 关门
      //   case "closeDoor":
      //     break;
      //   // 常开
      //   case "stayOpen":
      //     break;
      //   // 常关
      //   case "stayClose":
      //     break;
      //   // 正常
      //   case "normal":
      //     break;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  .channelsControlImageInfo {
    .channelsControlImageInfo_body {
      .el-checkbox-button {
        margin-bottom: 10px;
      }
      /deep/ .el-checkbox-button:nth-child(11) .el-checkbox-button__inner {
        border-left: 1px solid #dcdfe6;
        border-radius: 4px 0 0 4px;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
      }
      .channeItem-active {
        background-color: #ccc;
      }
      .active {
        background-color: #ccc;
      }
      .channeItem {
        display: block;
        padding: 4px;
        line-height: 20px;
        margin: 8px;
        width: 108px;
        height: 120px;
        position: relative;
        cursor: pointer;

        .channeItemImg {
          .doorImg {
            margin: 0 auto;
          }
          .doorOffline {
            background: url(../../assets/door/door-offline-icon.png) -4px -2px
              no-repeat;
            width: 50px;
            height: 55px;
          }

          .doorOpen {
            background: url(../../assets/door/door-open-icon.png) -20px -8px
              no-repeat;
            width: 50px;
            height: 55px;
          }

          .doorClose {
            background: url(../../assets/door/door-close-icon.png) -20px -8px
              no-repeat;
            width: 50px;
            height: 55px;
          }

          .doorAlwaysOpen {
            background: url(../../assets/door/door-openmonitor-icon.png) -20px -8px
              no-repeat;
            width: 50px;
            height: 55px;
          }

          .doorAlwaysClose {
            background: url(../../assets/door/door-warning-icon.png) -20px -8px
              no-repeat;
            width: 50px;
            height: 55px;
          }

          .doorAlwaysOpenButClose {
            background: url(../../assets/door/door-openclose-icon.png) -20px -8px
              no-repeat;
            width: 50px;
            height: 55px;
          }
          .doorAlwaysCloseButOpen {
            background: url(../../assets/door/door-closeopen-icon.png) -20px -8px
              no-repeat;
            width: 50px;
            height: 55px;
          }
        }
        .channeItemContent {
          text-align: center;
          .autoChangeLine {
          }
        }
      }
    }
  }
}
</style>
